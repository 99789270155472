<div class="head-title">
    <article>
        <h2>Explorar por Empresa</h2>
    </article>
</div>

<div *ngIf="loading == 0">
    <div class="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>

<div class="conteudo" *ngIf="loading == 1">
    <div class="gallery-image">
        <div class="pad">
            <div  class="img-box" *ngFor="let item of empresas">
                <div *ngIf="item.logo !== 'https://www.transparenttextures.com/patterns/asfalt-light.png'">
                    <a href="/?name=&company={{item.name}}">
                        <div class="frame">
                            <img src="{{item.logo}}" alt="{{item.name}}" loading="eager">
                        </div>
                        <div class="transparent-box">
                            <div class="caption">
                                <p>{{item.name}}</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div *ngIf="item.logo === 'https://www.transparenttextures.com/patterns/asfalt-light.png'">
                    <a href="/?name=&company={{item.name}}">
                        <div class="frame">
                            <img src="../../assets/logo.png" alt="{{item.name}}" loading="eager">
                        </div>
                        <div class="transparent-box">
                            <div class="caption">
                                <p>{{item.name}}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>