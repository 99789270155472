import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from './api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'NoExperience';
  public isLogedIn = false;

  constructor(public UserService: ApiService, public router: Router) {
  }

  async ngOnInit() {
    this.isLogedIn = await this.UserService.isLogedIn();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  public logout() {
    this.UserService.clearStorage();
    this.isLogedIn = false;
    window.location.href = '/';
  }

  public feelingLucky() {
    window.location.href = '/vagas/random';
  }

}
