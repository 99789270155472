import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api/api.service';

@Component({
  selector: 'app-explorar',
  templateUrl: './explorar.component.html',
  styleUrls: ['./explorar.component.css']
})
export class ExplorarComponent implements OnInit {
  public empresas: any;
  public loading = 0;

  constructor(public UserService: ApiService) { }

  ngOnInit(): void {
    this.UserService.obterListaEmpresas()
      .subscribe(
        data => {
          this.empresas = JSON.stringify(data);
          this.empresas = JSON.parse(this.empresas);
          this.loading = 1;
        }
      );
  }

}
