<div class="head-title">
    <article>
        <h2>FAQ</h2>
    </article>
</div>

<div *ngIf="loading == 0">
    <div class="loading"><div class="lds-dual-ring"></div></div>
</div>

<div class="content" *ngIf="loading == 1">
    <div class="faq-item">
        <mat-accordion>
            <div *ngFor="let item of faqs">
                <div *ngIf="(item.id === 1)">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{item.name}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p></p>
                        {{item.description}}
                    </mat-expansion-panel>
                </div>
                <div *ngIf="!(item.id === 1)">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{item.name}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p></p>
                        {{item.description}}
                    </mat-expansion-panel>
                </div>
            </div>
        </mat-accordion>
    </div>
</div>