import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { VagasComponent } from './vagas/vagas.component';
import { MatIconModule } from '@angular/material/icon';
import { DownloappComponent } from './downloapp/downloapp.component';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserComponent } from './user/user.component';
import { FormsModule } from '@angular/forms';
import { EntrarComponent } from './entrar/entrar.component';
import { HistoricoComponent } from './historico/historico.component';
import { GuardadoComponent } from './guardado/guardado.component';
import { TimeAgoPipe } from './time-ago.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotFoundComponent } from './not-found/not-found.component';
import { ExplorarComponent } from './explorar/explorar.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { PedirRecuperarSenhaComponent } from './pedir-recuperar-senha/pedir-recuperar-senha.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqComponent,
    VagasComponent,
    DownloappComponent,
    UserComponent,
    EntrarComponent,
    HistoricoComponent,
    GuardadoComponent,
    TimeAgoPipe,
    NotFoundComponent,
    ExplorarComponent,
    RecuperarSenhaComponent,
    PedirRecuperarSenhaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    HttpClientModule,
    MatExpansionModule,
    FormsModule,
    InfiniteScrollModule
    ],
  providers: [TimeAgoPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
