import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api/api.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-entrar',
  templateUrl: './entrar.component.html',
  styleUrls: ['./entrar.component.css']
})
export class EntrarComponent implements OnInit {
  public email_l!: string;
  public password_l!: string;
  public email!: string;
  public password!: string;
  public confirm_password!: string;
  public name!: string;
  public erro = '';
  public erro_l = '';
  public isLogedIn = false;


  constructor(public UserService: ApiService, private router: Router) { }

  async ngOnInit() {
    this.UserService
    this.isLogedIn = await this.UserService.isLogedIn();
    this.erro = "";
    this.erro_l = "";

    if (this.isLogedIn == true) {
      window.location.href = '/user';
    }
  }

  async register() {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    if (this.password != this.confirm_password) {
      this.erro = "Passwords não coincidem";
      await delay(3000);
      this.erro = "";
    }

    this.UserService.registar(this.email, this.password, this.name)
      .subscribe((res) => {
        this.UserService.saveStorage(this.email, this.password);
        window.location.href = '/user';
      }, async (err: any) => {
        let errmsg = err['error']['message'];

        if (errmsg == "Invalid email address"){
          this.erro = "Email inválido"
        } else if (errmsg == "User already registered.") {
          this.erro = "Email já registado"
        } else {
          this.erro = "Dados Inválidos";
        }

        await delay(3000);
        this.erro = "";
      });
  }

  login() {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    this.UserService.entrar(this.email_l, this.password_l)
      .subscribe((res: any) => {
        this.UserService.saveStorage(this.email_l, this.password_l);
        window.location.href = '/user';
      },async (err:any) => {
        let errmsg = err['error']['message'];
        
        if (errmsg == "Invalid email address"){
          this.erro_l = "Email inválido"
        } else if (errmsg == "Email is not registered") {
          this.erro_l = "Email não registado"
        } else {
          this.erro_l = "Dados Inválidos";
        }
        await delay(3000);
        this.erro_l = "";
      });
  }

}
