import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api/api.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  public username = "Sessão não iniciada";
  public isLogedIn = false;
  public loading = 0;
  
  constructor(public UserService: ApiService, private router: Router) { }


  async ngOnInit() {
    this.UserService;
    this.isLogedIn = await this.UserService.isLogedIn();

    if (this.isLogedIn == false) {
      this.router.navigateByUrl('/entrar');
    }
  
    let email = await this.UserService.getEmail();

    if (email === null || email === ""){
      this.router.navigateByUrl('/entrar');
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.username(email, password)
      .subscribe((res: any) => {
        this.username = res;
        this.loading = 1;
      });
    }
  }

}
