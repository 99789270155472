import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api/api.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {
  public password!: string;
  public confirm_password!: string;
  public erro = '';
  public isLogedIn = false;
  public done = 0;
  public hash: any;

  constructor(public UserService: ApiService, private route: ActivatedRoute, private router: Router) { }

  async ngOnInit() {
    this.UserService

    this.isLogedIn = await this.UserService.isLogedIn();
    this.erro = "";
    this.done = 0;
    this.password = "";
    this.confirm_password = "";
    this.hash = "";

    const routeParams = this.route.snapshot.paramMap;
    const hash = routeParams.get('hash');
    this.hash = hash;

    if (this.isLogedIn == true) {
      window.location.href = '/user';
    }
  }

  async recuperar() {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    if (this.password != this.confirm_password) {
      this.erro = "Passwords não coincidem";
      await delay(3000);
      this.erro = "";
    }

    this.UserService.recuperarsenha(this.hash, this.password)
      .subscribe(async (res: any) => {
        this.erro = "Senha alterada com sucesso";
        this.done = 1;
        await delay(3000);
        window.location.href = '/user';
      }, async (err: any) => {
        this.erro = "Algo não correu bem";
      });
  }
}
