
<div style="background-color: #454C56 !important; height: 100%; overflow:hidden; top: 0%; margin-bottom: -40px;">
<header class="masthead d-flex">
    <div class="container text-center my-auto">
      <h3 class="mb-5">
        <div style="margin-top: 350px;"></div>
      </h3>
      <a class="btn btn-primary btn-xl js-scroll-trigger" style="background-color: #FA6938 !important; border-color: #FA6938 !important;" href="/assets/noexperience.apk">Disponível para Android</a>
      <br>
      <a class="btn btn-primary btn-xl js-scroll-trigger" style="background-color: #FA6938 !important; border-color: #FA6938 !important; margin-top: 20px;" href="https://mobile.noexperience.pt/">Disponível para iPhone e iPad</a>
    </div>
    <div class="overlay"></div>
  </header>
</div>
  