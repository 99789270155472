<div class="banner">
  <img class="banner-img" src="{{banner}}" width="100%">
  <div class="content">
    <p class="banner-text">O teu futuro<br>começa aqui!</p>
  </div>
</div>

<div class="content">

  <div class="encontrar-vaga">
    <div class="encontrar-vaga">
      <form (ngSubmit)="filtros()">
        <div class="content-box-grey" style="width: auto; padding-bottom: 20px;">
          <p class="titulo-content-box">Encontrar Vaga</p>
          <div class="row">
            <input [(ngModel)]="name" name="name" class="input-content-box-1 col-sm" name="name"
              placeholder="ex: Gestor, Assistente Social, Python..." />

            <select [(ngModel)]="category" name="category" class="input-content-box-1 col-sm">
              <option selected value="Todos">Categoria</option>
              <option *ngFor="let item of categorias" value="{{item.id}}">{{item.name}}</option>
            </select>

            <select [(ngModel)]="location" name="location" class="input-content-box-1 col-sm">
              <option selected value="Todos">Local</option>
              <option *ngFor="let item of locais" value="{{item.id}}">{{item.name}}</option>
            </select>
          </div>
          <button type="submit">
            <mat-icon aria-hidden="false" style="margin-left: 10px; vertical-align: middle;">search</mat-icon>Pesquisar
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="space"></div>

  <div class="filtros-mobile">
    <form (ngSubmit)="filtros()">
      <div class="box">
        <div class="content-box-grey">
          <p class="titulo-content-box">Filtros</p>

          <div class="input-content-box">
            <input list="empresas" [(ngModel)]="company" name="empresa" placeholder="Empresa">
            <datalist id="empresas">
              <option *ngFor="let item of empresas" value="{{item.name}}">
            </datalist>
          </div>

          <select class="input-content-box-2" [(ngModel)]="schedule" name="schedule">
            <option selected value="Todos">Horário</option>
            <option *ngFor="let item of horarios" value="{{item.id}}">{{item.name}}</option>
          </select>

          <select class="input-content-box-2" [(ngModel)]="remote" name="remote">
            <option selected value="Todos">Trabalho Remoto</option>
            <option *ngFor="let item of remotos" value="{{item.id}}">{{item.name}}</option>
          </select>

          <select class="input-content-box-2" [(ngModel)]="contract" name="contract">
            <option selected value="Todos">Contrato</option>
            <option *ngFor="let item of contratos" value="{{item.id}}">{{item.name}}</option>
          </select>

          <select class="input-content-box-2" [(ngModel)]="published" name="published">
            <option selected value="Todos">Publicado</option>
            <option value="1">Últimas 24h</option>
            <option value="7">Últimos 7 dias</option>
            <option value="15">Últimos 15 dias</option>
            <option value="30">Últimos 30 dias</option>
          </select>
        </div>

        <button type="submit">Filtrar</button>
      </div>
    </form>

    <div *ngIf="pesquisaefec == 1" class="limparfiltros">
      <a href="/">
        <mat-icon aria-hidden="false"
          style="vertical-align: middle; font-size: large; margin-top: 5px; margin-right: -5px;">close</mat-icon>
        Limpar filtros
      </a>
    </div>

  </div>

  <div class="coluna-container">
    <div class="main-content coluna">
      <div class="vagas">
        <div class="content-box-grey" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()">
          <p class="titulo-content-box">{{ vagasContentBoxName }}</p>
          <div *ngIf="noresults == 1">
            <p class="vagaserror">Sem resultados correspondentes à Pesquisa.</p>
          </div>
          <div *ngIf="loading == 0">
            <p class="vagaserror">A carregar...</p>
          </div>
          <div *ngIf="loading == 1">
            <div *ngFor="let item of vagas['results']">
              <div class="vaga-content-box-white">
                <div class="grid">
                  <div class="row" style="padding: 10px;">

                    <div class="col-" style="margin: 10px;">
                      <a routerLink="/vagas/{{item.id}}">
                        <div class="frame">
                          <img loading="lazy" class="logo" src="{{item.company_logo}}">
                        </div>
                      </a>
                    </div>
                    <div class="col">
                      <a routerLink="/vagas/{{item.id}}">
                        <p class="titulo-vaga-content-box-white">{{item.name}}</p>
                        <div style="margin-left: 10px;">
                          <p class="empresa-vaga-content-box-white">{{item.company}}</p>
                          <div class="informacoes-vaga-content-box-white">
                            <div class="info">
                              <img loading="lazy" src="../../assets/pin.svg"><a>{{item.location}}</a>
                            </div>
                            <div class="info">
                              <img loading="lazy" src="../../assets/clock.svg"><a>{{item.schedule}}</a>
                            </div>
                            <div class="info">
                              <img loading="lazy" src="../../assets/document-text-outline.svg"><a>{{item.contract}}</a>
                            </div>
                            <div class="info">
                              <img loading="lazy" src="../../assets/laptop-outline.svg"><a>{{item.remote}}</a>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col" size="auto" class="right">
                      <div *ngIf="(item.favoritos === 'false')">
                        <mat-icon style="cursor: pointer;" (click)="guardarremovervaga(item.id)" name="bookmark_border">
                          bookmark_border</mat-icon>
                      </div>
                      <div *ngIf="(item.favoritos === 'true')">
                        <mat-icon style="cursor: pointer;" (click)="guardarremovervaga(item.id)" name="bookmark">
                          bookmark
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="tempo-vaga-content-box-white">{{item.pub_date | timeAgo}}</p>
              </div>
            </div>
          </div>
          <div *ngIf="loadingmore == 1">
            <p class="vagaserror">A carregar...</p>
          </div>
        </div>
      </div>
    </div>

    <div class="space"></div>

    <div class="sidebar-content coluna">

      <!-- SE LOGIN NAO EFETUADO: -->

      <div class="entrar" *ngIf="isLogedIn == false">
        <form (ngSubmit)="login()" ngNativeValidate>
          <div class="box">
            <div class="content-box-grey">
              <p class="titulo-content-box">Entrar</p>
              <p class="input-label">E-mail:</p>
              <div class="input-content-box">
                <input [(ngModel)]="email_l" type="email" name="email" required placeholder="oteumail@aempresa.com">
              </div>
              <p class="input-label">Password:</p>
              <div class="input-content-box">
                <input [(ngModel)]="password_l" minlength="8" type="password" name="password" required
                  placeholder="******">
              </div>
            </div>
            <button type="submit">Entrar</button>
            <a routerLink="/pedir-recuperar-senha" style="color: #00ADB5; margin: 5px;">Recuperar Senha</a>
            <p class="erro">{{erro_l}}</p>
          </div>
        </form>
      </div>

      <div class="space" *ngIf="isLogedIn == false"></div>

      <div class="registar" *ngIf="isLogedIn == false">
        <div class="box">
          <div class="content-box-grey" style="background-color: #303841">
            <p class="input-label" style="color: white; padding-top: 10px;">Ainda não fazes
              parte da nossa Plataforma?</p>
            <br>
          </div>
          <a id="button-registo" routerLink='/entrar'><button style="background-color: #ff7348;">Criar
              Conta</button></a>
        </div>
      </div>

      <div class="space" *ngIf="isLogedIn == false"></div>

      <!-- IDEPENDENTEMENTE DO LOGIN: -->

      <div class="filtros">
        <form (ngSubmit)="filtros()">
          <div class="box">
            <div class="content-box-grey">
              <p class="titulo-content-box">Filtros</p>
              <div class="input-content-box">
                <input list="empresas" [(ngModel)]="company" name="empresa" placeholder="Empresa">
                <datalist id="empresas">
                  <option *ngFor="let item of empresas" value="{{item.name}}">
                </datalist>
              </div>

              <select class="input-content-box-2" [(ngModel)]="schedule" name="schedule">
                <option selected value="Todos">Horário</option>
                <option *ngFor="let item of horarios" value="{{item.id}}">{{item.name}}</option>
              </select>

              <select class="input-content-box-2" [(ngModel)]="remote" name="remote">
                <option selected value="Todos">Trabalho Remoto</option>
                <option *ngFor="let item of remotos" value="{{item.id}}">{{item.name}}</option>
              </select>

              <select class="input-content-box-2" [(ngModel)]="contract" name="contract">
                <option selected value="Todos">Contrato</option>
                <option *ngFor="let item of contratos" value="{{item.id}}">{{item.name}}</option>
              </select>

              <select class="input-content-box-2" [(ngModel)]="published" name="published">
                <option selected value="Todos">Publicado</option>
                <option value="1">Últimas 24h</option>
                <option value="7">Últimos 7 dias</option>
                <option value="15">Últimos 15 dias</option>
                <option value="30">Últimos 30 dias</option>
              </select>
            </div>

            <button type="submit">Filtrar</button>
          </div>
        </form>

        <div *ngIf="pesquisaefec == 1" class="limparfiltros">
          <a href="/">
            <mat-icon aria-hidden="false"
              style="vertical-align: middle; font-size: large; margin-top: 5px; margin-right: -5px;">close</mat-icon>
            Limpar Filtros
          </a>
        </div>

      </div>

    </div>

  </div>