<div class="head-title">
    <article>
        <h2>Conta</h2>
    </article>
</div>

<div *ngIf="loading == 0">
    <div class="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>
<div *ngIf="loading == 1">
    <div class="space"></div>
    <div class="content">
        Estás logado como: {{username}}
    </div>
</div>