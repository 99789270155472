import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DownloappComponent } from './downloapp/downloapp.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { VagasComponent } from './vagas/vagas.component';
import { UserComponent } from './user/user.component';
import { EntrarComponent } from './entrar/entrar.component';
import { HistoricoComponent } from './historico/historico.component';
import { GuardadoComponent } from './guardado/guardado.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ExplorarComponent } from './explorar/explorar.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { PedirRecuperarSenhaComponent } from './pedir-recuperar-senha/pedir-recuperar-senha.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'vagas', component: HomeComponent },
  { path: 'pesquisa', component: HomeComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'vagas/:vagaId', component: VagasComponent },
  { path: 'download-app', component: DownloappComponent },
  { path: 'user', component: UserComponent },
  { path: 'entrar', component: EntrarComponent },
  { path: 'historico', component: HistoricoComponent },
  { path: 'explorar', component: ExplorarComponent },
  { path: 'guardado', component: GuardadoComponent },
  { path: 'recuperar-senha/:hash', component: RecuperarSenhaComponent},
  { path: 'pedir-recuperar-senha', component: PedirRecuperarSenhaComponent},
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
