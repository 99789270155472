<div class="navbarsticky">
  <nav class="navbar navbar-expand-lg navbar-dark " *ngIf="router.url !== '/download-app'"
    style="background-color: #303841;">
    <div class="container-fluid content">
      <a href="/"><img class="navbar-brand" src="../assets/logo.png" width="120"></a>
      <div class="downloadapp">
        <button routerLink="/download-app" type="button" class="navbar-toggler btn btn-light"
          style="background-color: #788A8B; color: white; ">Usar app <mat-icon aria-hidden="false"
            style="vertical-align: middle;">launch</mat-icon></button>
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/">Início</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" routerLink="/explorar">Explorar</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" routerLink="/faq">FAQ</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" style="cursor: pointer;" (click)="feelingLucky()">Sinto-me com Sorte</a>
          </li>
        </ul>
        <ul class="navbar-nav" *ngIf="isLogedIn == true">

          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/guardado">Guardado</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" routerLink="/historico">Histórico</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" routerLink="/user">Conta</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" style="cursor: pointer;" (click)="logout()">Sair</a>
          </li>
        </ul>
        <ul class="navbar-nav" *ngIf="isLogedIn == false">
          <li class="nav-item">
            <button type="button" class="btn btn-outline-info" routerLink="/entrar">Sou um Utilizador</button>
            <!--  <a class="nav-link active" routerLink="/entrar">Entrar/Registar</a>   -->
            
          </li>
          <li class="nav-item">
            <button type="button" class="btn btn-outline-warning" aria-current="page" routerLink="http://empresas.noexperience.pt"
            target="_blank">Sou uma Empresa</button>
            <!--
            <a class="nav-link active" aria-current="page" href="http://empresas.noexperience.pt" target="_blank"
              rel="noreferrer">Sou uma Empresa</a>
            -->

          </li>
         
        </ul>
      </div>
    </div>
  </nav>
</div>

<div class="body-component">
  <router-outlet></router-outlet>
</div>
<div class="space"></div>

<footer>
  <div>
    <u>Páginas</u>
    <ul>
      <li><a href="/">Início</a></li>
      <li><a routerLink="/faq">FAQ</a></li>
      <li><a style="cursor: pointer;" onMouseOver="this.style.color='#FF5722'" onMouseOut="this.style.color='white'"(click)="feelingLucky()">Sinto-me com Sorte</a></li>
      <li *ngIf="isLogedIn == false"><a routerLink="/entrar">Entrar/Registar</a></li>
      <li><a routerLink='/guardado'>Vagas Guardadas</a></li>
      <li><a routerLink='/historico'>Histórico</a></li>
      <li><a routerLink='/user'>Conta</a></li>
      <li *ngIf="isLogedIn == true"><a style="cursor: pointer;" (click)="logout()">Sair</a></li>
    </ul>
  </div>
  <div>
    <u>Empresas</u>
    <ul>
      <li><a href="http://empresas.noexperience.pt/" target="_blank" rel="noreferrer">Login</a></li>
      <li><a href="http://empresas.noexperience.pt/" target="_blank" rel="noreferrer">Registo</a></li>
      <li><a href="http://empresas.noexperience.pt/" target="_blank" rel="noreferrer">Conta</a></li>
      <li><a href="http://empresas.noexperience.pt/" target="_blank" rel="noreferrer">Anunciar Vaga</a></li>
    </ul>
  </div>
  <div>
    <u>Sobre Nós</u>
    <ul>
      <li><a href="http://empresas.noexperience.pt/templates/empresas/index.html" target="_blank" rel="noreferrer">Sobre
          o Website</a></li>
    </ul>
  </div>
  <div>
    <u>Privacidade</u>
    <ul>
      <li><a onMouseOver="this.style.color='#FF5722'" onMouseOut="this.style.color='white'">Termos e Condições</a></li>
      <li><a onMouseOver="this.style.color='#FF5722'" onMouseOut="this.style.color='white'">Política de Privacidade</a></li>
    </ul>
  </div>
  <div>
    <u>Contactos</u>
    <ul>
      <li><i class="fa fa-envelope"></i><a href="mailto:noexperience.pt@gmail.com">noexperience.pt@gmail.com</a></li>
    </ul>
  </div>
  <p><span>&#169;</span> NoExperience 2022 - Todos os Direitos Reservados</p>
</footer>