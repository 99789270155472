import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api/api.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-guardado',
  templateUrl: './guardado.component.html',
  styleUrls: ['./guardado.component.css']
})
export class GuardadoComponent implements OnInit {
  public vagas: any;
  public loading = 0;
  public noresults = 0;

  constructor(public UserService: ApiService, private router: Router) { }

  async ngOnInit() {
    this.UserService;

    let email = await this.UserService.getEmail();

    if (email === null || email === "") {
      this.router.navigateByUrl('/entrar');
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.obterguardado(email, password)
        .subscribe(
          data => {
            this.vagas = JSON.stringify(data);
            this.vagas = JSON.parse(this.vagas);
            if (JSON.stringify(data).length === 2) {
              this.noresults = 1;
            } else {
              this.noresults = 0;
            }

            this.loading = 1;
          }
        );
    }
  }

  async guardarremovervaga(x: string) {
    let email = await this.UserService.getEmail();

    if (email === null || email === "") {
      this.router.navigateByUrl('/entrar');
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.gerirguardadologin(x, email, password)
        .subscribe((res) => {
          //Find index of specific object using findIndex method.    
          let objIndex = this.vagas.findIndex(((obj: { id: string; }) => obj.id == x));

          if (res === "added") {
            this.vagas[objIndex].favourite = "true";
          } else if (res === "removed") {
            this.vagas[objIndex].favourite = "false";
          }

          let count = 0;
          for (let i of this.vagas) {
            if (i.favourite == "true") {
              count = 1;
            } 
          }

          if (count == 0) {
            this.noresults = 1;
          }
          
        }, async (err) => {
        });
    }
  }
}
