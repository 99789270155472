<div class="head-title">
    <article>
        <h2>Histórico</h2>
    </article>
</div>


<div class="space"></div>

<div class="content">
    <div class="vagas">
        <div class="content-box-grey">
            <p class="titulo-content-box">Visualizadas Recentemente</p>
            <div *ngIf="loading == 0">
                <p class="vagaserror">A Carregar...</p>
            </div>

            <div *ngIf="noresults == 1">
                <p class="vagaserror">As vagas que visualizar vão aparecer aqui</p>
            </div>

            <div *ngFor="let item of vagas">
                <div class="vaga-content-box-white" *ngIf="item.active == 'Yes'">
                    <div class="grid">
                        <div class="row" style="padding: 10px;">
                            <div class="col-" style="margin: 10px;">
                                <a routerLink="/vagas/{{item.id}}">
                                    <div class="frame">
                                        <img class="logo" src="{{item.company_logo}}">
                                    </div>
                                </a>
                            </div>
                            <div class="col">
                                <a routerLink="/vagas/{{item.id}}">
                                    <p class="titulo-vaga-content-box-white">{{item.name}}</p>
                                    <div style="margin-left: 10px;">
                                        <p class="empresa-vaga-content-box-white">{{item.company}}</p>
                                        <div class="informacoes-vaga-content-box-white">
                                            <div class="info">
                                                <img src="../../assets/pin.svg"><a>{{item.location}}</a>
                                            </div>
                                            <div class="info">
                                                <img src="../../assets/clock.svg"><a>{{item.schedule}}</a>
                                            </div>
                                            <div class="info">
                                                <img
                                                    src="../../assets/document-text-outline.svg"><a>{{item.contract}}</a>
                                            </div>
                                            <div class="info">
                                                <img src="../../assets/laptop-outline.svg"><a>{{item.remote}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col" size="auto" class="right">
                                <div *ngIf="(item.favoritos === 'false')">
                                    <mat-icon style="cursor: pointer;" (click)="guardarremovervaga(item.id)"
                                        name="bookmark_border">bookmark_border</mat-icon>
                                </div>
                                <div *ngIf="(item.favoritos === 'true')">
                                    <mat-icon style="cursor: pointer;" (click)="guardarremovervaga(item.id)"
                                        name="bookmark">bookmark</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="tempo-vaga-content-box-white">{{item.date_visited | timeAgo}}</p>
                </div>
            </div>
        </div>
    </div>
</div>