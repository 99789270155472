import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api/api.service";
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  public faqs: any;
  public loading = 0;

  constructor(public UserService: ApiService) { }

  ngOnInit() {
    this.UserService

    this.UserService.obterFaq()
      .subscribe(
        data => {
          this.faqs = JSON.stringify(data);
          this.faqs = JSON.parse(this.faqs)["results"];
          this.loading = 1;
        }
      );
  }

}
