import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api/api.service';

@Component({
  selector: 'app-pedir-recuperar-senha',
  templateUrl: './pedir-recuperar-senha.component.html',
  styleUrls: ['./pedir-recuperar-senha.component.css']
})
export class PedirRecuperarSenhaComponent implements OnInit {

  public email_l!: string;
  public erro_l = '';
  public isLogedIn = false;
  public done = 0;

  constructor(public UserService: ApiService) { }

  async ngOnInit() {
    this.UserService

    this.isLogedIn = await this.UserService.isLogedIn();
    this.erro_l = "";
    this.done = 0;

    if (this.isLogedIn == true) {
      window.location.href = '/user';
    }
  }

  pedir() {
    this.UserService.pedirrecuperarsenha(this.email_l)
      .subscribe((res: any) => {
        this.erro_l = "Consulte o seu email para continuar"
        this.done = 1
      }, async (err: any) => {
        this.erro_l = "Conta não registada"
      });
  }

}
