<div class="head-title">
  <article>
    <h2>Olá Desconhecido!</h2>
  </article>
</div>

<div class="content">

  <div class="space"></div>

  <article id="registo">
    <p class="sub-titulo">Se ainda não fazes parte da nossa Plataforma...</p>
    <form (ngSubmit)="register()" ngNativeValidate>
      <div class="box">
        <div class="content-box-grey">
          <p class="titulo-content-box">Criar conta</p>
          <p class="input-label">Nome:</p>
          <div class="input-content-box">
            <input [(ngModel)]="name" name="name" required autofocus type="text" placeholder="Miguel Pereira">
          </div>
          <p class="input-label">E-mail:</p>
          <div class="input-content-box">
            <input [(ngModel)]="email" name="email" required autofocus type="email" placeholder="oteumail@mail.com">
          </div>

          <p class="input-label">Password</p>
          <div class="input-content-box">
            <input [(ngModel)]="password" name="password" minlength="8" required type="password" id="password"
              placeholder="******">
          </div>

          <p class="input-label">Confirmar Password</p>
          <div class="input-content-box">
            <input required [(ngModel)]="confirm_password" minlength="8" name="confirm_password" type="password"
              id="cpassword" placeholder="******">
          </div>
        </div>
        <button type="submit">Registar</button>
        <p class="erro">{{erro}}</p>
      </div>
    </form>
  </article>

  <div class="space"></div>

  <article class="entrar">
    <p class="sub-titulo">Ou faz Login:</p>
    <form (ngSubmit)="login()" ngNativeValidate>
      <div class="box">
        <div class="content-box-grey">
          <p class="titulo-content-box">Entrar</p>
          <p class="input-label">E-mail:</p>
          <div class="input-content-box">
            <input [(ngModel)]="email_l" type="email" name="email" required placeholder="oteumail@mail.com">
          </div>
          <p class="input-label">Password:</p>
          <div class="input-content-box">
            <input [(ngModel)]="password_l" minlength="8" type="password" name="password" required placeholder="******">
          </div>
        </div>
        <button type="submit">Entrar</button>
        <a routerLink="/pedir-recuperar-senha" style="color: #00ADB5; margin: 5px;">Recuperar Senha</a>
        <p class="erro">{{erro_l}}</p>
      </div>
    </form>
  </article>

  <div class="space"></div>
</div>