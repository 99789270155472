import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {
  transform(d: any): string {

    let currentDate = new Date(new Date().toUTCString());
    let date = new Date(d + "Z");

    let year = currentDate.getFullYear() - date.getFullYear();
    let month = currentDate.getMonth() - date.getMonth();
    let day = currentDate.getDate() - date.getDate();
    let hour = currentDate.getHours() + 1 - date.getHours();
    let minute = currentDate.getMinutes() - date.getMinutes();
    let second = currentDate.getSeconds() - date.getSeconds();

    let createdSecond = (year * 31556926) + (month * 2629746) + (day * 86400) + (hour * 3600) + (minute * 60) + second;

    if (createdSecond >= 31556926) {
      let yearAgo = Math.floor(createdSecond / 31556926);
      return yearAgo > 1 ? "há " + yearAgo + " anos" : "há " + yearAgo + " ano";
    } else if (createdSecond >= 2629746) {
      let monthAgo = Math.floor(createdSecond / 2629746);
      return monthAgo > 1 ?"há " + monthAgo + " meses" : "há " + monthAgo + " mês";
    } else if (createdSecond >= 86400) {
      let dayAgo = Math.floor(createdSecond / 86400);
      return dayAgo > 1 ? "há " + dayAgo + " dias" : "há " + dayAgo + " dia";
    } else if (createdSecond >= 3600) {
      let hourAgo = Math.floor(createdSecond / 3600);
      return hourAgo > 1 ? "há " + hourAgo + " horas" : "há " + hourAgo + " hora";
    } else if (createdSecond >= 60) {
      let minuteAgo = Math.floor(createdSecond / 60);
      return minuteAgo > 1 ? "há " + minuteAgo + " min" : "há " + minuteAgo + " min";
    } else if (createdSecond < 60) {
      return createdSecond > 1 ? "há " + createdSecond + " seg" : "há " + createdSecond + " seg";
    } else if (createdSecond < 0) {
      return "Agora";
    } else {
      return "";
    }
  }
}