import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = "http://mst.midupe.pt:9000/api/";
  //api = "http://85.245.204.220:5000/";
  //api = "http://89.115.159.65:5000/";
  
  data: string;
  parsed: string;

  currentUser: any = null;

  constructor(private http: HttpClient) {
    this.data = '';
    this.parsed = '';
  }

  public obterVagas(): Observable<object> {
    // Define the data URL
    const dataUrl = this.api + "jobs";
    // Prepare the request
    return this.http.get(dataUrl);
  }

  public obterVagasLogin(email: any, password: any) {
    const dataUrl = this.api + 'jobs';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public obterListaCategorias(): Observable<object> {
    const dataUrl = this.api + 'lista-categorias';
    return this.http.get(dataUrl);
  }

  public obterListaEmpresas(): Observable<object> {
    const dataUrl = this.api + 'lista-empresas';
    return this.http.get(dataUrl);
  }

  public obterListaLocais(): Observable<object> {
    const dataUrl = this.api + 'lista-locais';
    return this.http.get(dataUrl);
  }

  public obterVaga(x: string): Observable<object> {
    const dataUrl = this.api + 'vagas/' + x;
    return this.http.get(dataUrl);
  }

  public obtervagalogin(x: string, email: any, password: any) {
    const dataUrl = this.api + 'vagas/' + x;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public reportarvaga(x: string, email: any, reason: any) {
    const dataUrl = this.api + 'vagas/' + x + '/report';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    if (reason == "") {
      reason = "Nada."
    }

    let data = {
      "email": email,
      "reason": reason
    }

    return this.http.post(dataUrl, data, options);
  }


  public obterFaq(): Observable<object> {
    const dataUrl = this.api + 'faq';
    return this.http.get(dataUrl);
  }

  public obterTipoContratos(): Observable<object> {
    const dataUrl = this.api + 'tipo-contratos';
    return this.http.get(dataUrl);
  }

  public obterTipoTrabalhosRemoto(): Observable<object> {
    const dataUrl = this.api + 'tipo-trabalhos-remotos';
    return this.http.get(dataUrl);
  }

  public obterTipoHorarios(): Observable<object> {
    const dataUrl = this.api + 'tipo-horarios';
    return this.http.get(dataUrl);
  }

  public entrar(email: any, password: any) {
    const dataUrl = this.api + 'entrar';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public pedirrecuperarsenha(email: any) {
    const dataUrl = this.api + 'recuperar-senha';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email
    }

    return this.http.post(dataUrl, data, options);
  }

  public recuperarsenha(hash: any, password: any) {
    const dataUrl = this.api + 'recuperar-senha/'+ hash;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public registar(email: any, password: any, name: any) {
    const dataUrl = this.api + 'registar';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password,
      "name": name
    }

    return this.http.post(dataUrl, data, options);
  }

  public username(email: any, password: any) {
    const dataUrl = this.api + 'user';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public search(name: any, company: any, category: any, location: any, schedule: any, remote: any, contract: any, published: any, start: any, limit: any) {
    const dataUrl = this.api + 'pesquisa?'
    +'name='+name
    +"&company="+ company
    +"&category="+category
    +"&location="+location
    +"&schedule="+schedule
    +"&remote="+remote
    +"&contract="+contract
    +"&published="+published
    +"&start="+start
    +"&limit="+limit;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(dataUrl);;
  }

  public searchLogin(email: any, password: any, name: any, company: any, category: any, location: any, schedule: any, remote: any, contract: any, published: any, start: any, limit: any) {
    const dataUrl = this.api + 'pesquisa?'
    +'name='+name
    +"&company="+ company
    +"&category="+category
    +"&location="+location
    +"&schedule="+schedule
    +"&remote="+remote
    +"&contract="+contract
    +"&published="+published
    +"&start="+start
    +"&limit="+limit;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);;
  }

  public saveStorage(email: any, password: any) {
    localStorage.setItem('email', email);
    localStorage.setItem('password', password);
  }

  public async getEmail() {
    let email = await localStorage.getItem('email');
    if (email == null || email == "") {
      email = "";
    }
    return email;
  }

  public async isLogedIn(): Promise<boolean> {
    let email = await this.getEmail();
    if (email == null || email == "") {
      return false;
    } else {
      return true;
    }
  }

  public async getPassword(): Promise<string> {
    let password = await localStorage.getItem('password');
    if (password == null || password == "") {
      password = "";
    }
    return password;
  }

  public clearStorage() {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
  }

  public obterhistorico(email: any, password: any) {
    const dataUrl = this.api + 'historico';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public obterguardado(email: any, password: any) {
    const dataUrl = this.api + 'guardado';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public verificarguardadologin(x: string, email: any, password: any) {
    const dataUrl = this.api + 'guardado/verificar/' + x;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }

  public verificarguardado(x: string): Observable<object> {
    const dataUrl = this.api + 'guardado/verificar/' + x;
    return this.http.get(dataUrl);
  }

  public gerirguardadologin(x: string, email: any, password: any) {
    const dataUrl = this.api + 'guardado/gerir/' + x;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl, data, options);
  }



}
