import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api/api.service";
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public vagasContentBoxName = "Vagas recentes";
  public isLogedIn = false;

  public vagas: any;

  public categorias: any;
  public locais: any;
  public horarios: any;
  public remotos: any;
  public contratos: any;
  public empresas: any;

  //Elementos da pesquisa
  public name = '';
  public company = '';
  public category = 'Todos';
  public location = 'Todos';
  public schedule = 'Todos';
  public remote = 'Todos';
  public contract = 'Todos';
  public published = 'Todos';

  // Pagination
  public limit = "10";
  public start = "1";

  public pesquisaefec = 0;
  public noresults = 0;

  // Elementos Login
  public email_l!: string;
  public password_l!: string;
  public erro_l = '';

  public loading = 0;
  public loadingmore = 0;
  public banner = "../assets/1.png";

  constructor(public UserService: ApiService, private router: Router, private urlRouter: Location) { }

  
  async ngOnInit() {
    this.erro_l = "";
    this.UserService;
    this.random_banner();

    this.isLogedIn = await this.UserService.isLogedIn();

    let email = await this.UserService.getEmail();

    if (email === null || email === "") {
      this.UserService.obterVagas()
        .subscribe(
          data => {
            this.vagas = JSON.stringify(data);
            this.vagas = JSON.parse(this.vagas);
            this.queryParams();
            this.loading = 1;
          }
        );
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.obterVagasLogin(email, password)
        .subscribe(
          data => {
            this.vagas = JSON.stringify(data);
            this.vagas = JSON.parse(this.vagas);
            this.queryParams();
            this.loading = 1;
          }
          , async (err) => {
            this.trylogin();
          });
    }

    this.UserService.obterListaCategorias()
      .subscribe(
        data => {
          this.categorias = JSON.stringify(data);
          this.categorias = JSON.parse(this.categorias);
        }
      );

    this.UserService.obterListaLocais()
      .subscribe(
        data => {
          this.locais = JSON.stringify(data);
          this.locais = JSON.parse(this.locais);
        }
      );

    this.UserService.obterTipoHorarios()
      .subscribe(
        data => {
          this.horarios = JSON.stringify(data);
          this.horarios = JSON.parse(this.horarios);
        }
      );

    this.UserService.obterTipoTrabalhosRemoto()
      .subscribe(
        data => {
          this.remotos = JSON.stringify(data);
          this.remotos = JSON.parse(this.remotos);
        }
      );

    this.UserService.obterTipoContratos()
      .subscribe(
        data => {
          this.contratos = JSON.stringify(data);
          this.contratos = JSON.parse(this.contratos);
        }
      );
      this.UserService.obterListaEmpresas()
      .subscribe(
        data => {
          this.empresas = JSON.stringify(data);
          this.empresas = JSON.parse(this.empresas);
        }
      );
  }

  random_banner() {
    let folder = "../assets/banner/";
    let random = Math.floor(Math.random() * 5) + 1; 
    this.banner = folder + random +".png"
  }

  public logout() {
    this.UserService.clearStorage();
    this.isLogedIn = false;
    window.location.href = '/';
  }

  trylogin() {

    this.UserService.entrar(this.UserService.getEmail, this.UserService.getPassword)
      .subscribe((res: any) => {
        window.location.href = '/';
      }, async (err) => {
        this.logout();
      });
  }

  async guardarremovervaga(x: string) {
    let email = await this.UserService.getEmail();

    if (email === null || email === "") {
      this.router.navigateByUrl('/entrar');
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.gerirguardadologin(x, email, password)
        .subscribe((res) => {
          //Find index of specific object using findIndex method.    
          let objIndex = this.vagas['results'].findIndex(((obj: { id: string; }) => obj.id == x));

          if (res === "added") {
            this.vagas['results'][objIndex].favoritos = "true"
          } else if (res === "removed") {
            this.vagas['results'][objIndex].favoritos = "false"
          }
        }, async (err) => {
        });
    }
  }

  filtros() {
    this.start = "";
    this.limit = "";
    this.urlRouter.replaceState('/?'
    +'name='+ this.name
    +"&company="+ this.company
    +"&category="+ this.category
    +"&location="+ this.location
    +"&schedule="+ this.schedule
    +"&remote="+ this.remote
    +"&contract="+ this.contract
    +"&published="+ this.published
    +"&start="+ this.start
    +"&limit="+ this.limit);
    this.search();
  }

  async search() {
    this.loading = 0;

    let email = await this.UserService.getEmail();

    if (email === null || email === "") {
      this.UserService.search(this.name, this.company, this.category, this.location, this.schedule, this.remote, this.contract, this.published, this.start, this.limit)
        .subscribe((res) => {
          if (JSON.stringify(res).length === 2) {
            this.noresults = 1;
          } else {
            this.noresults = 0;
          }
          this.vagas = res;
          this.vagasContentBoxName = "Resultados da pesquisa";
          this.pesquisaefec = 1;
          this.loading = 1;
        }, async (err) => {
        });
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.searchLogin(email, password, this.name, this.company, this.category, this.location, this.schedule, this.remote, this.contract, this.published, this.start, this.limit)
        .subscribe((res) => {
          if (JSON.stringify(res).length === 2) {
            this.noresults = 1;
          } else {
            this.noresults = 0;
          }
          this.vagas = res;
          this.vagasContentBoxName = "Resultados da pesquisa";
          this.pesquisaefec = 1;
          this.loading = 1;
        }, async (err) => {
        });
    }
  }

  login() {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    this.UserService.entrar(this.email_l, this.password_l)
      .subscribe((res: any) => {
        this.UserService.saveStorage(this.email_l, this.password_l);
        window.location.href = '/user';
      }, async (err:any) => {
        let errmsg = err['error']['message'];
        
        if (errmsg == "Invalid email address"){
          this.erro_l = "Email inválido"
        } else if (errmsg == "Email is not registered") {
          this.erro_l = "Email não registado"
        } else {
          this.erro_l = "Dados Inválidos";
        }
        await delay(3000);
        this.erro_l = "";
      });
  }

  queryParams() {
    let params: URLSearchParams = new URLSearchParams(window.location.search);
    let found = 0;
    if (params.has("name") === true) {
      this.name = params.get("name")!.toString();
      found = 1;
    }
    if (params.has("company")) {
      this.company = params.get("company")!.toString();
      found = 1;
    }
    if (params.has("category")) {
      this.category = params.get("category")!.toString();
      found = 1;
    }
    if (params.has("location")) {
      this.location = params.get("location")!.toString();
      found = 1;
    }
    if (params.has("schedule")) {
      this.schedule = params.get("schedule")!.toString();
      found = 1;
    }
    if (params.has("remote")) {
      this.remote = params.get("remote")!.toString();
      found = 1;
    }
    if (params.has("contract")) {
      this.contract = params.get("contract")!.toString();
      found = 1;
    }
    if (params.has("published")) {
      this.published = params.get("published")!.toString();
      found = 1;
    }

    if (params.has("start") === true) {
      this.start = params.get("start")!.toString();
      found = 1;
    }

    if (params.has("limit") === true) {
      this.limit = params.get("limit")!.toString();
      found = 1;
    }

    if (found == 1) {
      this.search();
    }
  }

  async onScroll() {
    let url = new URL("https://www.noexperience.pt/" + this.vagas['next'].toString());
    let next: URLSearchParams = new URLSearchParams(url.search);

    let next_start = next.get("start");
    let next_limit = next.get("limit");

    if (Number(next_start) > Number(this.start)) {
      this.loadingmore = 1;
      this.limit = next_limit!;
      this.start = next_start!;

      let email = await this.UserService.getEmail();

      if (email === null || email === "") {
        this.UserService.search(this.name, this.company, this.category, this.location, this.schedule, this.remote, this.contract, this.published, this.start, this.limit)
          .subscribe((res) => {
            if (JSON.stringify(res).length === 2) {
              this.noresults = 1;
            } else {
              this.noresults = 0;
            }
            let r: any = res;

            for (let i in r['results']) {
              this.vagas['results'].push(r['results'][i])
            }
            this.vagas['limit'] = r['limit'];
            this.vagas['previous'] = r['previous'];
            this.vagas['next'] = r['next'];

            this.vagasContentBoxName = "Resultados da pesquisa";
            this.pesquisaefec = 1;
            this.loadingmore = 0;
          }, async (err) => {
          });
      } else {
        let password = await this.UserService.getPassword();
        this.UserService.searchLogin(email, password, this.name, this.company, this.category, this.location, this.schedule, this.remote, this.contract, this.published, this.start, this.limit)
          .subscribe((res) => {
            if (JSON.stringify(res).length === 2) {
              this.noresults = 1;
            } else {
              this.noresults = 0;
            }
            let r: any = res;

            for (let i in r['results']) {
              this.vagas['results'].push(r['results'][i])
            }
            this.vagas['limit'] = r['limit'];
            this.vagas['previous'] = r['previous'];
            this.vagas['next'] = r['next'];

            this.vagasContentBoxName = "Resultados da pesquisa";
            this.pesquisaefec = 1;
            this.loadingmore = 0;
          }, async (err) => {
          });
      }
    }
  }

}