<div *ngIf="loading == 0">
    <div class="loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>

<div *ngIf="loading == 1">
    <div class="content" *ngFor="let item of vagas">
        <div *ngIf="item.active != 'Yes'" class="notactive">
            <div>
                <p>Esta vaga já não se encontra ativa.</p>
            </div>
        </div>

        <h1>
            {{item.name}}
        </h1>

        <div>
            <div class="vaga-content-box-white">
                <div class="grid">
                    <div class="row" style="padding: 10px;">
                        <div class="col">
                            <div style="margin-left: 10px; margin-top: -20px;">
                                <a class="empresa-vaga-content-box-white"
                                    href="/?name=&company={{item.company}}">{{item.company}}</a>
                                <div class="informacoes-vaga-content-box-white">
                                    <div class="info">
                                        <img src="../../assets/pin.svg"><a>{{item.location}}</a>
                                    </div>
                                    <div class="info">
                                        <img src="../../assets/clock.svg"><a>{{item.schedule}}</a>
                                    </div>
                                    <div class="info">
                                        <img src="../../assets/document-text-outline.svg"><a>{{item.contract}}</a>
                                    </div>
                                    <div class="info">
                                        <img src="../../assets/laptop-outline.svg"><a>{{item.remote}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col" size="auto" class="right">
                            <div *ngIf="((favoritos === 'false'))">
                                <mat-icon (click)="guardarremovervaga(item.id)" style="cursor: pointer;"
                                    name="bookmark_border">bookmark_border</mat-icon>
                            </div>
                            <div *ngIf="((favoritos === 'true'))">
                                <mat-icon (click)="guardarremovervaga(item.id)" style="cursor: pointer;"
                                    name="bookmark">bookmark</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="col-" style="margin: 10px;">
                        <div class="frame">
                            <a href="/?name=&company={{item.company}}"><img class="logo" src="{{item.company_logo}}"
                                    style="padding-top: 50px;"></a>
                        </div>
                    </div>
                    <p class="tempo-vaga-content-box-white">{{item.pub_date | timeAgo}}</p>
                    <div *ngIf="(isLogedIn && item.active == 'Yes')">
                        <a class="reportar" (click)="openmodal()">Algum problema? <a
                                style="text-decoration: underline;">Reportar vaga</a></a>
                        <div *ngIf="reported == 1">
                            <p style="margin-left: 20px;"><i>Vaga reportada com sucesso. Obrigado!</i></p>
                        </div>
                        <div *ngIf="reported == 2">
                            <p style="margin-left: 20px;"><i>Ocorreu algum erro ao reportar a vaga!</i></p>
                        </div>
                    </div>
                    <div *ngIf="(isLogedIn==false && item.active == 'Yes')">
                        <a class="reportar" routerLink="/entrar">Algum problema? <a
                                style="text-decoration: underline;">Reportar vaga</a></a>
                    </div>
                </div>

            </div>
        </div>

        <div class="descricao">
            <div class="content-box-grey">
                <p class="titulo-content-box-grey">A empresa</p>
                <div class="texto-descricao">
                    <p class="text">{{item.company_description}}</p>
                </div>
            </div>
            <div class="separador"></div>
            <div class="content-box-grey">
                <p class="titulo-content-box-grey">Detalhes da vaga</p>
                <div class="texto-descricao">
                    <p class="text" [innerHTML]="item.description"></p>
                </div>
            </div>
        </div>

        <div class="candidatar" *ngIf="item.active == 'Yes'">
            <div *ngIf="(isLogedIn == true)">
                <a class="botao-candidatar" target="_blank" rel="noreferrer"
                    href="mailto:{{item.company_email}}?subject=Emprego/Job%20-%20{{item.name}}&body=Escreva%20aqui%20:)%0A%0A%0A%0A%0AFonte:%20NoExperience.pt">Candidatar</a>
            </div>
            <div *ngIf="(isLogedIn == false)">
                <a class="botao-candidatar" routerLink="/entrar">Candidatar</a>
            </div>
        </div>
    </div>
</div>

<div id="myModal" class="modal" style="display: {{modaldisplay}};">
    <div class="modal-content content">
        <span class="close" (click)="modalclose()">&times;</span>
        <div class="text">
            <form (ngSubmit)="reportvaga()" ngNativeValidate>
                <br>
                <p><b>O que se passa de errado com esta vaga?</b></p>
                <br>
                <textarea class="reason" [(ngModel)]="reason" rows="4" name="reason" required
                    placeholder="Indique aqui..."></textarea>
                <br>
                <br>
                <button type="submit" class="botao-reportar">Reportar</button>
                <br>
            </form>
        </div>
    </div>
</div>