<div class="head-title">
  <article>
    <h2>Recuperar Senha</h2>
  </article>
</div>

<div class="content">

  <div class="space"></div>

  <article class="entrar">
    <form (ngSubmit)="pedir()">
      <div class="box">
        <div class="content-box-grey">
          <p class="titulo-content-box">Recuperar Senha</p>
          <p class="input-label">E-mail:</p>
          <div class="input-content-box">
            <input [(ngModel)]="email_l" type="email" name="email" required placeholder="oteumail@mail.com">
          </div>

        </div>
        <div *ngIf="done == 0">
          <button type="submit">Avançar</button>
        </div>
        <p class="erro">{{erro_l}}</p>
      </div>
    </form>
  </article>

  <div class="space"></div>
</div>