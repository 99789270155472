<div class="head-title">
  <article>
    <h2>Recuperar Senha</h2>
  </article>
</div>

<div class="content">

  <div class="space"></div>

  <article class="entrar">
    <form (ngSubmit)="recuperar()">
      <div class="box">
        <div class="content-box-grey">
          <p class="titulo-content-box">Recuperar Senha</p>
          <p class="input-label">Password:</p>
          <div class="input-content-box">
            <input [(ngModel)]="password" type="password" minlength="8" name="password" required placeholder="******">
          </div>
          <p class="input-label">Confirmar Password</p>
          <div class="input-content-box">
            <input required [(ngModel)]="confirm_password" minlength="8" name="confirm_password" type="password"
              id="cpassword" placeholder="******">
          </div>

        </div>
        <div *ngIf="done == 0">
          <button type="submit">Alterar Senha</button>
        </div>
        <div *ngIf="done == 1">
          <button routerLink="/entrar">Fazer Login</button>
        </div>
        <p class="erro">{{erro}}</p>
      </div>
    </form>
  </article>

  <div class="space"></div>
</div>