import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api/api.service";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vagas',
  templateUrl: './vagas.component.html',
  styleUrls: ['./vagas.component.css']
})
export class VagasComponent implements OnInit {
  public vagas: any;
  public vagaId: any;
  public email = "";
  public favoritos = "";
  public isLogedIn = false;
  public loading = 0;
  public reported = 0;
  public reason = "";

  public modaldisplay = "none";

  constructor(public UserService: ApiService, private route: ActivatedRoute, private router: Router) { }

  async ngOnInit() {
    this.UserService
    const routeParams = this.route.snapshot.paramMap;
    const vagaId = routeParams.get('vagaId');
    this.vagaId = vagaId
    this.reason = "";

    this.isLogedIn = await this.UserService.isLogedIn();

    this.email = await this.UserService.getEmail();
    //Verificar se utilizador esta logado
    if (this.email === null || this.email === "") {
      this.UserService.obterVaga(this.vagaId)
        .subscribe(
          data => {
            this.vagas = JSON.stringify(data);
            this.vagas = JSON.parse(this.vagas);
            this.hideEmailsLinks();
            this.removewhitespace();
            if (this.vagaId === "random") {
              window.location.href = '/vagas/' + this.vagas[0]['id'];
            }
          }, async (err) => {
            this.router.navigateByUrl('/404');
          });
      this.favoritos = "false";
      this.loading = 1;
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.obtervagalogin(this.vagaId, this.email, password)
        .subscribe(
          data => {
            this.vagas = JSON.stringify(data);
            this.vagas = JSON.parse(this.vagas);
            this.removewhitespace();
            if (this.vagaId === "random") {
              window.location.href = '/vagas/' + this.vagas[0]['id'];
            }
          }, async (err) => {
            this.router.navigateByUrl('/404');
          });
      this.UserService.verificarguardadologin(this.vagaId, this.email, password)
        .subscribe(
          data => {
            this.favoritos = JSON.stringify(data);
            this.favoritos = JSON.parse(this.favoritos);
            this.loading = 1;
          }
        );
    }
  }

  async reportvaga() {
    let email = await this.UserService.getEmail();

    if (email === null || email === "") {
      this.router.navigateByUrl('/entrar');
    } else {
      this.UserService.reportarvaga(this.vagaId, email, this.reason)
        .subscribe((res) => {
          this.reported = 1
          this.reason = "";
        }, async (err) => {
          console.log(err)
          this.reported = 2
        });
    }
    this.modalclose();
  }

  async guardarremovervaga(x: any) {
    let email = await this.UserService.getEmail();

    if (email === null || email === "") {
      this.router.navigateByUrl('/entrar');
    } else {
      let password = await this.UserService.getPassword();
      this.UserService.gerirguardadologin(x, email, password)
        .subscribe((res) => {
          let estadofav = JSON.stringify(res);
          estadofav = JSON.parse(estadofav);
          if (estadofav === 'added') {
            this.favoritos = "true";
          } else {
            this.favoritos = 'false';
          }
        }, async (err) => {
        });
    }
  }

  hideEmailsLinks() {
    let result = this.vagas[0]['description'].replace(/<a.*?<\/a>/g, "<a href=" + "/entrar" + ">[Faça login para visualizar]</a>");
    this.vagas[0]['description'] = result;
  }

  removewhitespace() {
    let options = this.vagas[0]['description'].replaceAll('<p> </p>', '');
    this.vagas[0]['description'] = options;
  }

  openmodal() {
    this.modaldisplay = "block";
  }
  
  // When the user clicks on <span> (x), close the modal
  modalclose() {
    this.modaldisplay = "none";
  }
  

}
